import React from 'react'
import logo from '../images/logo.png'
import { MdKeyboardArrowUp } from 'react-icons/md'
import { MdDinnerDining  } from "react-icons/md";
import { BiChevronRight } from "react-icons/bi";


import './FooterStyles.css'

import { Link } from 'react-scroll'

const Footer = () => {
    return (
        <div className='footer'>
            <div className="container">
                <div className="top">
                    <div className="logo-footer">
                        <img src={logo} alt="logo" />  
                        <h2><strong> Softmusk</strong></h2>
                    </div>
                    <Link activeClass="active" to="top" spy={true} smooth={true} duration={100} >
                        <MdKeyboardArrowUp className='icon___i' />
                    </Link>

                </div>
                <div className="col-container">
                    <div className="col">
                    <h5>SoftMusk Info Pvt Ltd.</h5>
                    <h6 className='bss__footer'>_</h6>
                    <p>3rd Floor, FS Plaza, Beside Tata Goldplus,
                        Near Mahaveer Bhavan, Khanapur Rd, Tilakwadi,
                        Belgaum, Karnataka 590006
                        0831 4263506</p>
                    </div>
                    <div className="col">
                    <h5>Company</h5>
                    <h6 className='bss__footer'>_</h6>
                    <p><BiChevronRight/> Terms & Conditions</p>
                    <p><BiChevronRight/> Privacy Policy</p>
                    <p><BiChevronRight/> Support</p>
                    <p><BiChevronRight/> FAQ</p>
                    </div>
                    <div className="col">
                    <h5>Quick Links</h5>
                    <h6 className='bss__footer'>_</h6>
                    <p><BiChevronRight/> About</p>
                    <p><BiChevronRight/> Services</p>
                    <p><BiChevronRight/> Contact</p>
                    </div>
                    <div className="col">
                    <h5>Office Hours</h5>
                    <h6 className='bss__footer'>_</h6>
                    <p>Mon-Sat: 09 a.m - 06 p.m (GMT + 05:30)</p>
                    <p>Sunday Closed</p>
                    <MdDinnerDining className='spoon'/>
                </div>
                    </div>
                    
                </div>
            </div>
    
    )
}

export default Footer
