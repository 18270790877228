import React from 'react'
import './SerHome.css'
const SerHome = () => {
  return (
  
      <div className='s_home'>
        <div className="container">
            <div className="content">
                <h1><strong>Services</strong></h1>
        
            </div>
        </div>
    </div>
    
  )
}

export default SerHome
