import React from "react";
import './ContactHome.css'
const ContactHome = () => {
  return (
    <div className="c_home">
      <div className="container">
        <div className="content">
          <h1>
            <strong>Contact</strong>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ContactHome;
