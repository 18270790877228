import React from 'react'
import './ClientSays.css'
import {ImQuotesLeft, ImQuotesRight} from 'react-icons/im'

import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    name : 'CEO',
    mname: 'M/s S G INDUSTRIES',
    review: 'Best design & development for industrial level websites and services. Nice team work and support. Thanks, Softmusk & Team.'
  },
  {
    name : 'Director',
    mname: 'Kirti Surgicals',
    review: 'Good web & software services for busiess solutions.'
  },
  {
    name : 'Principal',
    mname: 'KV NO.3 BELGAUM',
    review: 'Good place for internships and academic projects.'
  },
  {
    name : 'Engineering Student',
    mname: 'SNEHAL KHOT',
    review: 'Best understandings, supportive and helpfulness team "I gained a new sense of professionalism and a clearer view of what it meant to be in the professional world. I would advise everyone to take the opportunity and do an internship in softmusk."'
  },
  {
    name : 'Topovan Of Shri Kumarswamiji',
    mname: 'Dharwad',
    review: 'Nice work and totally saticefy, Softmusk Info Private Ltd. provides good work. Thank you team and company.'
  },
]

const ClientSays = () => {
  return (
    <section>
      <h3>Check what's our clients say</h3>
      <h3>about us</h3>
     
      
      <Swiper className='container testimonials__container'
      // install Swiper modules
      modules={[Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={{ clickable: true }}>
        {
          data.map(({name, mname, review}, index) => {
            return (
              <SwiperSlide key={index} className='testimonial'>
                   <ImQuotesLeft className="comas"/>
          <p className='client__review'>
         {review}
          </p>
          <ImQuotesRight className="comas_s"/>
          <h5 className='client__name'>{name}</h5>
          <h6 className='client__mname'>{mname}</h6>
        
        </SwiperSlide>
            )
          })
        }


       

      </Swiper>
    </section>
  )
}

export default ClientSays
