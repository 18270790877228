import React from 'react'
import Footer from '../component/Footer'
import Navbar  from '../component/Navbar'
const Support = () => {
  return (
    <>
      <Navbar/>
      <h2>This is Support page</h2>
      <Footer />
    </>
  )
}

export default Support
